/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    div: "div"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 665px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 62.650602409638545%; position: relative; bottom: 0; left: 0; background-image: url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAMBAgX/xAAXAQADAQAAAAAAAAAAAAAAAAABAgMF/9oADAMBAAIQAxAAAAHMYqYadACn/8QAFxAAAwEAAAAAAAAAAAAAAAAAAAECIP/aAAgBAQABBQJFw5z/AP/EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABYQAAMAAAAAAAAAAAAAAAAAAAAgIf/aAAgBAQAGPwIq/wD/xAAZEAACAwEAAAAAAAAAAAAAAAABEQAQIVH/2gAIAQEAAT8hFlRDjeGhCa//2gAMAwEAAgADAAAAEEvv/8QAFhEAAwAAAAAAAAAAAAAAAAAAARAx/9oACAEDAQE/EBF//8QAFhEAAwAAAAAAAAAAAAAAAAAAARAx/9oACAECAQE/EDV//8QAGxABAAICAwAAAAAAAAAAAAAAAQAREDEhUbH/2gAIAQEAAT8QphtaJyh1UPkdxpETH//Z'); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"LEGO toy origin\"\n        title=\"LEGO toy origin\"\n        src=\"/static/62d6ba818b3a643b79294e00e2158ef8/0d8e0/lego.jpg\"\n        srcset=\"/static/62d6ba818b3a643b79294e00e2158ef8/4d5fb/lego.jpg 166w,\n/static/62d6ba818b3a643b79294e00e2158ef8/558f0/lego.jpg 333w,\n/static/62d6ba818b3a643b79294e00e2158ef8/0d8e0/lego.jpg 665w,\n/static/62d6ba818b3a643b79294e00e2158ef8/90334/lego.jpg 998w,\n/static/62d6ba818b3a643b79294e00e2158ef8/38b44/lego.jpg 1280w\"\n        sizes=\"(max-width: 665px) 100vw, 665px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  })), "\n", React.createElement(_components.p, null, "In 1916 a Danish woodworker purchased a woodworking shop in Bilund, Denmark."), "\n", React.createElement(_components.p, null, "In the beginning the new owner of the shop, Ole Kirk Christiansen, put his efforts into making furniture."), "\n", React.createElement(_components.p, null, "The Great Depression hit around 1929 and caused there to be a shortage of carpentry needs and customers. As a result Ole began working on smaller projects and eventually began experimenting with wooden toy production."), "\n", React.createElement(_components.p, null, "The thirties was a time of toy production for Ole’s shop but business was still not booming."), "\n", React.createElement(_components.p, null, "It wasn’t until after World War II that Ole had access to plastics and plastic molding machines."), "\n", React.createElement(_components.p, null, "He began working with plastics as well as a system for children to build novel things with his toys."), "\n", React.createElement(_components.p, null, "Eventually, the idea of the versatile brick came along where they could be locked into place with other bricks allowing nearly anything to be built (and taken apart)."), "\n", React.createElement(_components.p, null, "It wasn’t until the late 50’s when the system was really perfected and sales started taking off for the company."), "\n", React.createElement(_components.p, null, "Since its humble beginnings in a small woodworking shop, LEGO has become a world wide name in children’s toys."), "\n", React.createElement(_components.p, null, "The name for the company was derived from the Dutch words “leg godt” which means to “play well”."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
